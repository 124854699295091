import ContentMargins from "../components/layout/ContentMargins";
import Layout from "../components/layout/layout";
import PageTitle from "../components/contentHeadings/PageTitle";
import React from 'react';
import Seo from "../components/Seo";
import SubHeading from "../components/contentHeadings/SubHeading";
import TertiaryHeading from "../components/contentHeadings/TertiaryHeading";

const Personvern = () => (
    <Layout>
        <Seo title={"Personvernerklæring"} />
        <ContentMargins topMargin>
            <PageTitle>Personvernerklæring</PageTitle>
            <SubHeading>Om virksomheten - kontaktinformasjon</SubHeading>
            <p>Benedikte Vippeextensions driver to vippeextensions salonger. Salongen på Grünerløkka drives av Benedikte Vippeextensions AS, og salongen i Kirkegata drives av Benedikte Vippeextensions Sentrum AS.</p>
            <p>Salongene har felles nettside og felles bookingsystem, og innsamlede personopplysninger er tilgjengelige for begge salongene. Har du spørsmål eller andre henvendelser knyttet til vår behandling av dine personopplysninger kan du kontakte det selskapet du ønsker, uavhengig av hvor du har bestilt time. </p>
            <p>
                Selskapsnavn: Benedikte Vippeextensions AS<br/>
                Organisasjonsnummer: 818 687 362<br/>
                <br/>
                Telefon: +47 480 77 349<br/>
                E-post: <a
                href={'mailto:kontakt@benediktevippeextensions.no'}>kontakt@benediktevippeextensions.no</a><br/>
                <br/>
                <br/>
                Besøksadresse:<br/>
                Søndre Gate 2<br/>
                0550 Oslo<br/>
                Norge
                <br/>
                <br/>
                Postadresse:<br/>
                Benedikte Vippeextensions AS<br/>
                Prinsens Gate 4B<br/>
                0152 Oslo<br/>
                Norge
            </p>
            <p>
                Selskapsnavn: Benedikte Vippeextensions Sentrum AS<br/>
                Organisasjonsnummer: 818 687 362<br/>
                <br/>
                Telefon: +47 480 77 349<br/>
                E-post: kontakt@benediktevippeextensions.no<br/>
                <br/>
                Besøksadresse:<br/>
                Kirkegata 12<br/>
                0153 Oslo<br/>
                Norge<br/>
                <br/>
                Postadresse:<br/>
                Benedikte Vippeextensions Sentrum AS<br/>
                Prinsens Gate 4B<br/>
                0152 Oslo<br/>
                Norge
            </p>
            <SubHeading>Del A: Dette samler vi inn når du booker time</SubHeading>
            <ol>
                <li>Navn</li>
                <li>E-postadresse</li>
                <li>Telefonnummer</li>
                <li>Adresse</li>
                <li>Postnummer og sted</li>
                <li>Hvilke tjenestere du har bestilt, og detaljene knyttet til bestillingene, som valgt behandling, tidspunkt, vippetekniker og filial.</li>
            </ol>
            <p>Å oppgi opplysningene er nødvendig for å inngå en avtale om at vi skal yte våre tjenester dersom du booker via nettsiden.</p>
            <TertiaryHeading>Formål</TertiaryHeading>
            <ol>
                <li>Oppfyllelse av tjenesteavtale: Vi må vite hvem som har bestilt de forskjellige timene slik at riktig person kommer til riktig vippetekniker i salongen, og slik at vi kan flytte/avbestille timen din dersom du ønsker dette. Vi må også kunne kontakte deg i forbindelse med timen din, f.eks. dersom vi ikke kan yte behandlingen pga. sykdom, og for å kunne fakturere deg ved manglende oppmøte.</li>
                <li>Oppfyllelse av tjenesteavtale: Vi beholder informasjonen også etter at tjenesten er utført, i tilfelle reklamasjoner, tilbakemeldinger, og for å kunne dokumentere avtalens innhold.</li>
                <li>Vi benytter også informasjonen for statistiske formål. Bla. for å måle andelen av kunder som kommer tilbake for flere behandlinger.</li>
                <li>Vi bruker informasjonen til å kunne avdekke misbruk av bookingsystemet. (F.eks. store mengder bookinger fra samme person, hvor hensikten er å fortrenge andre kunder fra å booke). </li>
            </ol>
            <p>Behandlingsgrunnlaget er avtale (altså timebestillingen).</p>
            <TertiaryHeading>Hvordan samles opplysningene inn?</TertiaryHeading>
            <ol>
                <li>Ved at du booker time på nettet, og fyller ut skjemaet for kundeinformasjon.</li>
                <li>Ved at du ringer oss og bestiller en time over telefon og i den forbindelse gir oss opplysningene. </li>
                <li>Ved at du bestiller en time ved oppmøte i salongen, og i den forbindelse gir oss opplysningene. </li>
                <li>Ved at du kontakter oss på annen måte og booker en time og i den forbindelse gir oss opplysningene. </li>
            </ol>
            <TertiaryHeading>Lagring av personopplysninger</TertiaryHeading>
            <p>Opplysningene knyttet til alle bookingene dine oppbevares i 5 år fra din siste booking. Deretter slettes eller anonymiseres opplysningene knyttet til alle timene du har booket. Informasjonen slettes ikke ved avbestilling av timen.</p>
            <p>Benedikte Vippeextensions AS og Benedikte Vippeextensions Sentrum AS behandlingsansvarlige. All informasjon deles med begge salongene ettersom disse har felles nettside og bookingsystem. </p>
            <p>Våre databaseleverandører er Google og Algolia, og disse er derfor databehandlere når det gjelder opplysningene i del A.</p>
            <SubHeading>Del B: Dette samler vi inn når du bruker nettsiden, benediktevippeextensions.no</SubHeading>
            <p>Siden vår bruker informasjonskapsler for å kunne dele følgende informasjon med våre anonnsører:</p>
            <ol>
                <li>Om du booker time eller ikke.</li>
                <li>Hvilke behandlinger du klikker på, og hvor langt i bestillingsprosessen du kommer.</li>
                <li>Hvilke sider du besøker på nettsiden.</li>
            </ol>
            <p>Formål:</p>
            <ol>
                <li>Informasjonen brukes til å kunne forbedre målrettingen av vår egen markedsføring.</li>
            </ol>
            <p>Behandlingsgrunnlaget er samtykke</p>
            <p>Om du booker eller ikke deles med Google (Google Analytics, Google Ads) og Facebook (Facebook Pixel). Dette gjør at deres systemer automatisk optimaliseres slik at annonsene vises til personer som har større sannsynlighet for å ende opp med å booke en time. </p>
            <p>
                {"Les om hvordan informasjonen som samles inn av Google brukes her: "}
                <a href={"https://policies.google.com/technologies/partner-sites"}>https://policies.google.com/technologies/partner-sites</a>
            </p>
            <SubHeading>Dine rettigheter</SubHeading>
            <TertiaryHeading>INNSYN</TertiaryHeading>
            <p>Du har rett til innsyn i alle personopplysninger vi har lagret om deg. Ta kontakt så sender vi deg informasjonen i det formatet du ønsker. </p>
            <TertiaryHeading>RETTING</TertiaryHeading>
            <p>Har vi registrert feilaktige opplysninger om deg kan du kreve at vi retter dem.</p>
            <TertiaryHeading>RETT TIL Å PROTESTERE</TertiaryHeading>
            <p>For opplysningene som knytter seg til del B har du rett til å protestere mot behandling av dine personopplysninger. Les mer om dette her, og ta gjerne kontakt om du har spørsmål: <a href={"https://www.datatilsynet.no/rettigheter-og-plikter/den-registrertes-rettigheter/rett-til-a-protestere/"}>https://www.datatilsynet.no/rettigheter-og-plikter/den-registrertes-rettigheter/rett-til-a-protestere/</a></p>
            <TertiaryHeading>SLETTING</TertiaryHeading>
            <p>I noen tilfeller kan du kreve at vi sletter opplysningene vi har lagret om deg. Dette vil f.eks. være tilfelle dersom du allerede har mottatt behandlingen og det ikke er noen grunn til at vi kommer til å trenge dokumentasjon på avtalens innhold i fremtiden. Les mer om retten til sletting her: <a href={"https://www.datatilsynet.no/rettigheter-og-plikter/den-registrertes-rettigheter/rett-til-sletting/"}>https://www.datatilsynet.no/rettigheter-og-plikter/den-registrertes-rettigheter/rett-til-sletting/</a></p>
            <TertiaryHeading>BEGRENSNING</TertiaryHeading>
            <p>Mener du at de lagrede personopplysningene er feilaktige, eller du har brukt din rett til å protestere kan kreve at personopplysningene dine ikke skal brukes under behandlingen av kravet. Les mer her: <a href={"https://www.datatilsynet.no/rettigheter-og-plikter/den-registrertes-rettigheter/rett-til-begrensning/"}>https://www.datatilsynet.no/rettigheter-og-plikter/den-registrertes-rettigheter/rett-til-begrensning/</a>.</p>
            <TertiaryHeading>TILBAKETREKKING AV SAMTYKKE</TertiaryHeading>
            <p>Du kan når som helst trekke tilbake et samtykke til behandling av dine personopplysninger. Behandlingen av personopplysningene i del B bygger på et samtykke, og du kan tilbakekalle samtykket ved å klikke her. For opplysningene i del A, se avsnittet om sletting ovenfor.</p>
            <TertiaryHeading>KLAGE TIL DATATILSYNET</TertiaryHeading>
            <p>Brudd på personvernlovgivningen kan klages inn for Datatilsynet. Les mer her: <a href={"https://www.datatilsynet.no/om-datatilsynet/kontakt-oss/hvordan-kan-jeg-klage-til-datatilsynet/"}>https://www.datatilsynet.no/om-datatilsynet/kontakt-oss/hvordan-kan-jeg-klage-til-datatilsynet/</a></p>
        </ContentMargins>
    </Layout>
);

export default Personvern;